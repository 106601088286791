export enum EnRoles {
  // master admin
  sysAdmin = 'sysAdmin',
  // can read and edit
  admin = 'admin',
  // only read
  roAdmin = 'roAdmin',
  // shop manager
  shopManager = 'shopManager',
  // customer
  customer = 'customer',
  // customer
  restocker = 'restocker',
  // customer
  trustee = 'trustee',
  //suport
  support = 'support',
}
