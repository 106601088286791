import { takeLatest, call, put, all } from 'redux-saga/effects'
import api from '../../../services/api'

// Extend the type of api.defaults.headers to include Authorization
declare module 'axios' {
  export interface HeadersDefaults {
    Authorization: string;
  }
}
import { toast } from 'react-toastify'
import history from '../../../services/history'
import { signInSuccess, signFailure } from './actions'
import { CLOSE_SIDEBAR_CLIENT, OPEN_SIDEBAR_CLIENT } from '../userClient/actions'
import { message } from 'antd'
import { EnRoles } from 'src/interfaces/roles'

export function* signIn({ payload }: { payload: { email: string, password: string } }): SagaIterator {
  try {
    const { email, password } = payload

    const response = yield call(api.post, 'adm/sessions', {
      email,
      password,
    })

    localStorage.setItem('@usernameadmin:servemei', email)
    localStorage.setItem('@passwordadmin:servemei', password)

    if (response.data) {
      const { token, user, roles } = response.data

      if (
        roles.every(
          (role: { roleName: string }) =>
            role.roleName === EnRoles.trustee ||
            role.roleName === EnRoles.restocker ||
            role.roleName === EnRoles.customer,
        )
      ) {
        throw new Error('Usuário inválido para o acesso');
      }


      api.defaults.headers.Authorization = `Bearer ${token}`

      localStorage.setItem('@passwordadmin:id', user._id)

      yield put(signInSuccess(token, user, roles))

      yield put(CLOSE_SIDEBAR_CLIENT())

      message.success('Visão de admin assumida.')
    }
  } catch (error: any) {
    if (error.message === "Usuário inválido para o acesso") {
      yield put(signFailure())
      return message.error(error.message);
    }
    if (error?.response?.data?.message === "Username/Password invalido") {
      message.error('Usuário ou senha inválidos.')
    } else {
      message.error(error?.response?.data?.message || 'Falha na autenticação, verifique seus dados e tente novamente.')
    }
    yield put(signFailure())
  }
}

export function* signInUser({ payload }: { payload: { id: string } }): SagaIterator {
  try {
    const { id } = payload

    const response = yield call(api.post, 'adm/sessions/user', { id: id })

    if (response.data) {
      const { token, user, roles } = response.data

      if (!roles) {
        throw new Error('Usuário inválido para o acesso');
      }

      api.defaults.headers.Authorization = `Bearer ${token}`

      yield put(signInSuccess(token, user, roles))

      yield put(OPEN_SIDEBAR_CLIENT())

      toast.success(`Visão de ${user.email} assumida.`)
    }
  } catch (error: any) {
    if (error.message === "Usuário inválido para o acesso") {
      yield put(signFailure())
      return message.error(error.message);
    }

    toast.error('Falha na autenticação, verifique seus dados e tente novamente.')
  }
}

import { SagaIterator } from 'redux-saga';

export function* signUp({ payload }: { payload: { email: string, password: string, confirmPassword: string } }): SagaIterator {
  try {
    const { email, password, confirmPassword } = payload

    const response = yield call(api.post, 'novo-usuario', {
      email,
      password,
      confirmPassword,
    })

    if (response.data.success) {
      history.push('/dashboard')
    }
  } catch (error: any) {
    toast.error('Falha na autenticação, verifique seus dados e tente novamente.')
    yield put(signFailure())
  }
}

export function setToken({ payload }: { payload: { auth: { token: string } } }) {
  if (!payload) return

  const { token } = payload.auth

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function signOut() {
  history.push('/')
}

export default all([
  takeLatest('persist/REHYDRATE' as any, setToken),
  takeLatest('@auth/SIGN_IN_REQUEST' as any, signIn),
  takeLatest('@auth/SIGN_UP_REQUEST' as any, signUp),
  takeLatest('@auth/SIGN_OUT' as any, signOut),
  takeLatest('@auth/SIGN_IN_REQUEST_USER' as any, signInUser),
])
